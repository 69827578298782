import BaseBean from "@/utils/BaseBean";
import {ElForm} from 'element-plus';
import config from "@/utils/config";
export interface ILoginForm {
    usercode: string
    password: string
    verifyCode: string
}
export interface ILoginDataObj{
    refMap:Map<string,any>
    sliderStatus: boolean
    yzmType:number
    backgroundImage:string
    useYzm: boolean
    loginForm: ILoginForm
    loginFormRules:TFormRule
    fullscreenLoading:boolean
    loginMsg:string
    otherParams:any
}
export default class LoginUtil extends BaseBean{
    public appInstant:any;
    public dataObj: ILoginDataObj;
    constructor(proxy:any,appInstant:any,dataObj:ILoginDataObj){
        super(proxy);
        this.appInstant=appInstant;
        this.dataObj=dataObj;
    }

    public async loginInit(dataObj:TCommonObj):Promise<void>{
        let res = await this.utils.Api.loginInitData({applications:config.applications});
        if(res.result){
            this.dataObj.otherParams.needMultiLanguage=res.needMultiLanguage;
            sessionStorage.setItem("needMultiLanguage", res.needMultiLanguage);
            sessionStorage.setItem("encryptData", res.encryptData);
            sessionStorage.setItem("canRegister", res.canRegister);
            sessionStorage.setItem("animations", res.animations);
            dataObj.otherParams.canRegister=res.canRegister;
            dataObj.useYzm=res.useYzm;
            dataObj.yzmType=res.yzmType;
            dataObj.loginMsg=res.loginMsg;
            if(res.imageSource && res.imageSource.length!=0){
                let picIndex=Math.floor(Math.random()*res.imageSource.length);
                dataObj.backgroundImage='url('+res.imageSource[picIndex].path+')';
                sessionStorage.setItem("imageSource", this.utils.UtilPub.encrypt(JSON.stringify(res.imageSource)));
            }else{
                const staticImgPromise = import('@/assets/login/'+Math.floor(Math.random() * 5)+'.png');
                staticImgPromise.then((module) =>dataObj.backgroundImage='url('+module.default+')');
            }
        }else{
            this.proxy.$message.error(this.proxy.$t('login.loginInitWrong'))
        }
    }

    public checkBrows():void{
        let userAgent=navigator.userAgent;
        if(userAgent.indexOf("Chrome")==-1){
            this.proxy.$notify({
                title: this.proxy.$t('tools.tip'),
                type: 'warning',
                dangerouslyUseHTMLString: true,
                position: 'top-left',
                message: this.proxy.$t('login.browerMsg'),
                duration: 0
            });
        }
    }

    public async doNext(field:string):Promise<void>{
        switch (field) {
            case "usercode":
                this.dataObj.refMap.get('passwordRef').focus();
                break;
            case "password":
                if (this.dataObj.useYzm && this.dataObj.yzmType==1) {
                    this.dataObj.refMap.get('verifyCodeRef').focus();
                } else {
                    this.submitForm();
                }
                break;
            case "verifyCode":
                this.submitForm();
                break;
        }
    }

    public submitForm():void{
        this.dataObj.refMap.get('loginFormRef').validate(async (valid:BufferSource) => {
            if (valid) {
                await this.doLogin();
            } else {
                this.proxy.$message.info(this.proxy.$t('login.completeMsg'));
            }
        });
    }

    public async doLogin():Promise<void>{

        if(this.dataObj.useYzm && this.dataObj.yzmType==1){
            let isVerifyCodeRight=this.dataObj.refMap.get('verifyCodeComRef').verfiCode(this.dataObj.loginForm.verifyCode);
            if(!isVerifyCodeRight){
                this.utils.Tools.info({message: this.proxy.$t('login.verifyCodeWrong')});
                return ;
            }
        }else if(this.dataObj.useYzm && this.dataObj.yzmType==0 && !this.dataObj.sliderStatus){
            this.utils.Tools.info({message: this.proxy.$t('login.moveSliderNote')});
            return;
        }
        this.dataObj.fullscreenLoading=true;
        this.dataObj.loginForm.password = this.utils.UtilPub.encrypt(this.dataObj.loginForm.password);
        let res = await this.utils.Api.login(this.dataObj.loginForm);
        if(res[this.utils.Const.code]==this.utils.Const._0000){
            this.store.commit("setNeedCorp", res.needCorp);
            this.store.commit("setNeedDept", res.needDept);
            this.store.commit("setNeedPerson", res.needPerson);
            this.store.commit("setUseDataLevel", res.useDataLevel);
            this.store.commit("setUploadSaveType", res.uploadSaveType);
            this.store.commit("setMsgReceivePermission", res.hasMessageReceivePermission);
            this.store.commit("setChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);

            sessionStorage.setItem("imageSource", this.utils.UtilPub.encrypt(JSON.stringify(res.imageSource)));
            sessionStorage.setItem("userMenus", this.utils.UtilPub.encrypt(res.userMenus));
            sessionStorage.setItem("showLayer", res.showLayer);
            if(res.showLayer){
                sessionStorage.setItem("layerShowType", res.layerShowType);
                if(res.userLayerData)sessionStorage.setItem("userLayerData", this.utils.UtilPub.encrypt(res.userLayerData));
            }
            sessionStorage.setItem("welcomeMsg", res.welcomeMsg);

            this.dataObj.loginForm.password = '';

            let userInfo:IStoreUserInfo=Object.assign({},this.dataObj.loginForm,{hasEditPortal:res.hasEditPortal,userName:res.userName});
            sessionStorage.setItem("userInfo",this.utils.UtilPub.encrypt(JSON.stringify(userInfo)));
            sessionStorage.setItem(this.utils.Const.jfAccessToken, res[this.utils.Const.jfAccessToken]);
            localStorage.setItem(this.utils.Const.jmReportAccessToken, res[this.utils.Const.jfAccessToken]);

            sessionStorage.setItem("aloneServerInfo",this.utils.UtilPub.encrypt(res.aloneServerInfo));
            sessionStorage.setItem('billPages',this.utils.UtilPub.encrypt(res.billPages));
            sessionStorage.setItem('applications',this.utils.UtilPub.encrypt(res.applications));
            sessionStorage.setItem('mainMsg',res.mainMsg);


            if(this.proxy.$socket && this.proxy.$socket.readyState!=1){
                let socketUrl=this.proxy.$socket.url.substr(0,this.proxy.$socket.url.lastIndexOf('/'));
                socketUrl=socketUrl+"/"+this.dataObj.loginForm.usercode;
                let $socket=new WebSocket(socketUrl);
                this.proxy.$socket = $socket;
            }else{
                await this.utils.Websocket.createWebSocketCon(this.dataObj.loginForm.usercode,this.appInstant);
            }
            await this.utils.Websocket.initWebSocket(this.appInstant);

            this.dataObj.fullscreenLoading=false;

            if(sessionStorage.getItem("lockScreen")){
                let lockScreen:ILockScreen={lockScreen:false,lockScreenState:'unLocked'};
                sessionStorage.setItem("lockScreen",JSON.stringify(lockScreen));
            }

            if(res.systemTitle) sessionStorage.setItem("systemTitle",res.systemTitle);
            if(this.utils.Tools.isAnimationOpen('loginAnimation')){
                this.loginActAnimation();
                setTimeout(()=> {
                    this.toPage(res);
                }, 1000);
            }else{
                this.toPage(res);
            }
        }else{
            this.dataObj.loginForm.password = '';
            this.utils.Tools.info({message: res.msg});
            if(this.dataObj.useYzm && this.dataObj.yzmType==1){
                this.dataObj.refMap.get('verifyCodeComRef').createCode();
                this.dataObj.loginForm.verifyCode = '';
            }else if(this.dataObj.useYzm && this.dataObj.yzmType==0){
                this.dataObj.sliderStatus=false;
                this.dataObj.refMap.get('sliderRef').resetSlider();
            }
            this.dataObj.fullscreenLoading=false;
        }
    }

    public loginActAnimation():void{
        let width=window.innerWidth;
        let height=window.innerHeight;
        let dom:any=document.getElementById("loginForm");
        dom.style="transform: translate("+width+"px,-"+height+"px) scale(0) rotate(45deg);transition: all 3s;";
    }

    public toPage(res:any):void{
        if(res.showLayer){
            this.proxy.$router.push('/layer');
        }else{
            this.proxy.$router.push('/');
        }
        this.utils.Tools.success({message: this.proxy.$t('login.loginSuccessMsg')});
    }
}